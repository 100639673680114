const poster = async (url: string, token: string | null, data: any) => {
  if (!token) {
    throw new Error("Token is not available");
  }

  const newUrl = process.env.NEXT_PUBLIC_CUSTOMER_API_HOST + url;

  try {
    const res = await fetch(newUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errorData;
      try {
        // Attempt to parse error response as JSON
        errorData = await res.json();
      } catch (e) {
        // Fallback to text if JSON parsing fails
        errorData = await res.text();
      }
      throw new Error(
        JSON.stringify({
          status: res.status,
          statusText: res.statusText,
          errorData: errorData,
        })
      );
    }

    const contentType = res.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      try {
        // Attempt to parse successful response as JSON
        return await res.json();
      } catch (e) {
        // Handle JSON parsing errors
        console.error("Error parsing JSON response:", e);
        return null; // or handle appropriately
      }
    } else {
      return await res.text();
    }
  } catch (error) {
    console.error("Error posting data", error);
    throw error;
  }
};

const fetchToken = async () => {
  try {
    const res = await fetch("/api/token", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(`Error fetching access token: ${res.statusText}`);
    }

    const data = await res.json();
    return data.accessToken;
  } catch (error) {
    console.error("Error fetching access token", error);
    throw error;
  }
};

const postAPI = async (url: string, data: any) => {
  try {
    const token = await fetchToken();
    return await poster(url, token, data);
  } catch (error) {
    if (error instanceof Error) {
      try {
        const parsedError = JSON.parse(error.message);
        console.error("API Error:", parsedError);
        // You can handle the error here or rethrow it
        throw new Error(
          `API Error: ${parsedError.status} ${
            parsedError.statusText
          }\n${JSON.stringify(parsedError.errorData)}`
        );
      } catch {
        // If parsing fails, it's not our custom error object
        console.error("Error in API call:", error.message);
        throw error;
      }
    } else {
      console.error("Unknown error in API call:", error);
      throw new Error("Unknown error in API call");
    }
  }
};

export default postAPI;
